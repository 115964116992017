
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonList,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonSearchbar,


  onIonViewDidEnter,
 } from '@ionic/vue';

import { 
  checkmark, 
  addCircleOutline,
  ellipseOutline,
  checkmarkCircleOutline,
  checkmarkCircle,
  create,
  shuffle,
  ellipsisVertical,
  trashOutline,
  chevronBack,
}  from 'ionicons/icons';

import { defineComponent, ref, computed } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { Guest } from '@/lib/api-endpoints';
import { useStore } from '@/store';
import { guestStatusById, guestStatuses } from '@/lib/guest-statuses';
import t from '@/translation';

export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonIcon,
    IonList,
    //IonListHeader,
    IonItem,
    IonItemDivider,
    IonLabel,
    //IonCheckbox,
    //IonSpinner,
    IonNote,
    //IonReorder,
    //IonReorderGroup,
    IonSelect,
    IonSelectOption,
    //IonMenu,
    //IonMenuButton,
    //IonPopover,
    //IonText,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonBackButton,
    IonSearchbar,
    

    //MInput,
    //MSelect,
    //MPassword,
  },
  setup(){

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const status = ref<number>(-1);
    const term = ref<string>("");
    const sortBy = ref<string>("last_name");

    onIonViewDidEnter(()=>{
      if ( route.params.statusId as string ){
        status.value = parseInt(route.params.statusId as string);
      }
      term.value="";
    });
    
    const title = computed(()=>{
      const result = guestStatusById(status.value);
      if (result){
        return result.title;
      }
      return "All Guests"
    });

    const guestMatchLabels: {[key: string]: string} = {
      /* eslint-disable @typescript-eslint/camelcase */
      first_name: t("First Name"),
      last_name: t("Last Name"),
      address_line_1: t("Address Line 1"),
      address_line_2: t("Address Line 2"),
      //city: t("City"),
      suburb: t("Suburb"),
      state: t("State"),
      postcode: t("Postcode"),
      country: t("Country"),
      email: t("Email"),
      phone: t("Phone"),
      table: t("Table"),
      dietary: t("Dietary"),
      special_request: t("Special Request(s)"),
      /* eslint-enable @typescript-eslint/camelcase */
    }

    function compare( a: Guest, b: Guest ) {
      let aVal: string, bVal: string;
      if (sortBy.value == 'table'){
        aVal = a.table;
        bVal = b.table;
      } else {
        aVal = a.last_name;
        bVal = b.last_name;
      }

      if ( aVal < bVal ){
        return -1;
      }
      if ( aVal > bVal ){
        return 1;
      }
      
      return 0;
    }

    const filteredGuests = computed(() => {
      return store.state.guests.filter((guest)=>{
        guest.matchesKey = "";
        guest.matchesValue = "";

        if ((status.value !== -1) && (guest.invited !== status.value)){
          return false;
        }

        if (term.value){
          for (const [key, value] of Object.entries(guest)){
            const strValue = value.toString()
            if ((key in guestMatchLabels) && (strValue.toLowerCase().includes(term.value.toLowerCase()))){
              if (key != 'first_name' && key != 'last_name'){
                guest.matchesKey = guestMatchLabels[key];
                guest.matchesValue = strValue;
              }
              return true;
            }
          }
        } else {
          // returned when term.value is not set
          return true;
        }

        // returned when term.value is set but no match is found.
        return false;

      }).sort(compare);
    });

    function onItemClicked(guestId: number){
      router.push('/tabs/guests/guest/' + guestId );
    }

    function onDeleteClicked(id: number){
      store.dispatch('deleteGuest', id);
    }

    function onAddClicked(){
      let tag = "";
      for (const [key, value] of guestStatuses){
        if (value.statusId == status.value){
          tag = key;
          break;
        }
      }
      router.push('/tabs/guests/guest/' + tag )
    }


    return {

      
      checkmark,
      addCircleOutline,
      create,
      shuffle,
      ellipsisVertical,
      trashOutline,
      chevronBack,
      ellipseOutline,
      checkmarkCircleOutline,
      checkmarkCircle,

      router,
      onItemClicked,
      onDeleteClicked,
      onAddClicked,
      store,

      guestStatuses,
      guestStatusById,

      status,
      term,
      filteredGuests,
      title,
      sortBy,

    }
  }
});
